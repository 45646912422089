<template>
  <div>
    <v-row class="table-actions-row">
      <v-col sm="4" cols="12" class="pl-0">
        <breadcrumbs :items="crumbs"></breadcrumbs>
      </v-col>
      <v-col sm="4" class="d-flex justify-end align-center"></v-col>
      <v-col sm="4" cols="12" class="pl-0 d-flex flex-column justify-end align-end">
        <button
            class="btn export-btn btn-square btn-info p-8"
            @click="exportRequsts"
        >
          Экспорт заявки в службу доставки
        </button>
        <div class="export-desc">
          В форме указываются только заказы со статусом "Передан в доставку"
        </div>
      </v-col>
    </v-row>
    <localization :language="'ru-RU'">
      <intl :locale="'ru'">
        <Grid
            ref="grid"
            class="agent-user-dealer-orders"
            :resizable="true"
            :sortable="true"
            :filterable="true"
            :pageable="gridPageable"
            :filter="filter"
            @datastatechange="dataStateChange"
            :take="take"
            :skip="skip"
            :sort="sort"
            :page-size="pageSize"
            :total="total"
            :data-items="dataResult"
            :columns="columns"
            :expand-field="'expanded'"
            @expandchange="expandChange"
            :detail="detailTemplate"
            @sortchange="sortChange"
        >
          <template v-slot:status_filter>
            <drop-down-list
                class="categories-filter"
                :data-items="OrderStatuses"
                :text-field="'name'"
                :data-item-key="'key'"
                :default-item="{ id: null, name: 'Все' }"
                @change="selectStatus"
            />
          </template>
          <template v-slot:subdivision_delivery_is_order_urgent_filter>
            <boolean-filter
                @change="
                    selectFilter(
                        'structural_subdivision_delivery_is_order_urgent',
                        $event.value.value
                    )
                "
            >
            </boolean-filter>
          </template>
          <template v-slot:subdivision_delivery_type_filter>
            <drop-down-list
                class="categories-filter"
                :data-items="DeliveryTypes"
                :text-field="'name'"
                :data-item-key="'key'"
                :default-item="{ id: null, name: 'Все' }"
                @change="selectDeliveryType"
            />
          </template>
          <template v-slot:status="{ methods, props: { dataItem } }">
            <td>
              {{
                getProductOrderStatusNameByKey(
                    dataItem.product_order_status
                )
              }}
            </td>
          </template>
          <template
              v-slot:subdivision_delivery_is_order_urgent="{
                  props: { dataItem },
              }"
          >
            <td>
              <div>
                <span
                    class="m-badge m-badge--wide m-badge--wide"
                    :class="{
                        'm-badge--success':
                            dataItem.structural_subdivision_delivery_is_order_urgent,
                        'm-badge--danger':
                            !dataItem.structural_subdivision_delivery_is_order_urgent,
                    }"
                >
                  {{
                    dataItem.structural_subdivision_delivery_is_order_urgent
                        ? 'ДА'
                        : 'НЕТ'
                  }}
                </span>
              </div>
            </td>
          </template>
          <template
              v-slot:subdivision_delivery_type="{
                  props: { dataItem },
              }"
          >
            <td>
              {{
                getDeliveryTypeNameByKey(
                    dataItem.structural_subdivision_delivery_type
                )
              }}
            </td>
          </template>

          <template
              v-slot:structural_subdivision_act="{
                  props: { dataItem },
              }"
          >
            <td>
              <a
                  v-if="dataItem.structural_subdivision_act"
                  class="actions-btn btn-square btn-info"
                  style="color: #fff"
                  download
                  :href="dataItem.structural_subdivision_act"
              >
                Скачать файл
              </a>
              <span
                  v-else
                  class="m-badge m-badge--wide m-badge--danger"
              >
                  Файл не загружен
              </span>
            </td>
          </template>
          <template
              v-slot:structural_subdivision_mx="{ props: { dataItem }, }"
          >
            <td>
              <a class="actions-btn btn-square btn-info"
                 style="color: #fff"
                 download
                 :href="dataItem.mx3_file_link">
                Скачать
              </a>
            </td>
          </template>

          <template v-slot:detail="{ props: { dataItem } }">
            <div class="detail-wrapper">
              <v-tabs v-model="tab">
                <v-tab key="details" href="#details"
                >Подробная информация
                </v-tab
                >
                <v-tab key="order-items" href="#order-items"
                >Состав заказа
                </v-tab
                >
                <v-tab key="client" href="#client"
                >Клиент
                </v-tab
                >
                <v-tab key="history" href="#history"
                >История
                </v-tab
                >
                <v-tabs-items v-model="tab">
                  <v-tab-item key="details" id="details">
                    <v-container
                        class="ml-0 grey lighten-5"
                    >
                      <div>
                        <table
                            class="details-table table table-striped m-table"
                        >
                          <tbody>
                          <tr>
                            <td>Номер:</td>
                            <td>
                              <p>
                                {{
                                  dataItem.product_order_number
                                }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Дата оплаты:
                            </td>
                            <td>
                              <p>
                                {{
                                  dataItem.product_order_date
                                      ? moment(
                                          dataItem.product_order_date
                                      ).format(
                                          'DD.MM.YYYY'
                                      )
                                      : ''
                                }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>Статус:</td>
                            <td>
                              <p>
                                {{
                                  getProductOrderStatusNameByKey(
                                      dataItem.product_order_status
                                  )
                                }}
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Структурное
                              подразделение:
                            </td>
                            <td>
                              <p>
                                {{
                                  dataItem.structural_subdivision_name
                                }}
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td>Сумма:</td>
                            <td>
                              <p>
                                {{
                                  dataItem.amount
                                }}
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Цель заказа:
                            </td>
                            <td>
                              <p>
                                {{
                                  dataItem.structural_subdivision_delivery_order_purpose
                                }}
                              </p>
                            </td>
                          </tr>
                          <tr></tr>
                          <tr>
                            <td
                                class="font-weight-bold"
                                colspan="2"
                            >
                              <b
                              >Контактная
                                информация</b
                              >
                            </td>
                          </tr>

                          <tr>
                            <td>
                              ФИО получателя:
                            </td>
                            <td>
                              <p>
                                {{
                                  dataItem.structural_subdivision_delivery_recipient_name
                                }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              E-mail
                              получателя:
                            </td>
                            <td>
                              <p>
                                {{
                                  dataItem.structural_subdivision_delivery_additional_customer_email
                                }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Е-mail
                              заказчика:
                            </td>
                            <td>
                              <p>
                                {{
                                  dataItem.structural_subdivision_delivery_customer_email
                                }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Контактный
                              телефон получателя:
                            </td>
                            <td>
                              <p>
                                {{
                                  dataItem.structural_subdivision_delivery_contact_number
                                }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td width="350">
                              Дополнительный
                              контактный
                              телефон получателя:
                            </td>
                            <td>
                              <p>
                                {{
                                  dataItem.structural_subdivision_delivery_optional_contact_number
                                }}
                              </p>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item
                      key="order-items"
                      id="order-items"
                  >
                    <v-container
                        class="ml-0 grey lighten-5"
                    >
                      <div
                          v-if="
                              dataItem.allow_editing &&
                              (dataItem.editing ||
                              (dataItem.items &&
                              dataItem.items.length >= 1))
                          "
                          class="order-item-actions"
                      >
                        <template
                            v-if="!dataItem.editing"
                        >
                          <CButton
                              @click="editOrderPositions(dataItem)"
                              class="edit-order-btn"
                              color="info"
                          >
                            Редактировать
                          </CButton>
                        </template>
                        <template v-else>
                          <CButton
                              :disabled="
                                  !dataItem.deletedItems ||
                                  !dataItem.deletedItems.length
                              "
                              @click="
                                  currentItem = dataItem;
                                  showOrderPositionDeletingModal = true;
                              "
                              class="edit-order-btn mr-3"
                              color="info"
                          >
                            Cохранить
                          </CButton>

                          <CButton
                              @click="cancelOrderPositionEditing(dataItem)"
                              class="edit-order-btn"
                              color="danger"
                          >
                            Отменить
                          </CButton>
                        </template>
                      </div>

                      <div
                          v-if="
                              dataItem.items &&
                              dataItem.items.length
                          "
                      >
                        <table
                            class="order-items-table-header"
                            role="presentation"
                        >
                          <thead role="presentation">
                          <tr
                              role="row"
                              aria-rowindex="1"
                          >
                            <th
                                aria-readonly="true"
                                aria-selected="false"
                                role="columnheader"
                                tabindex="-1"
                                class="k-header"
                                rowspan="1"
                                colspan="1"
                                aria-colindex="1"
                            >
                              Товар
                              <span
                                  class="k-column-resizer"
                                  style="
                                                                        display: block;
                                                                        right: 0px;
                                                                    "
                              ></span>
                            </th>
                            <th
                                aria-readonly="true"
                                aria-selected="false"
                                role="columnheader"
                                tabindex="-1"
                                class="k-header"
                                rowspan="1"
                                colspan="1"
                                aria-colindex="3"
                                style=""
                            >
                              Количество
                              <span
                                  class="k-column-resizer"
                                  style="
                                                                        display: block;
                                                                        right: 0px;
                                                                    "
                              ></span>
                            </th>
                          </tr>
                          </thead>
                        </table>
                        <table
                            class="order-items-table k-grid-table"
                            role="presentation"
                            style="
                                                        transform: translateY(
                                                            0px
                                                        );
                                                    "
                        >
                          <tbody role="presentation">
                          <tr
                              v-for="(item, key) in dataItem.items"
                              :key="key"
                              class="k-alt"
                              data-kendo-grid-item-index="0"
                              role="row"
                              aria-rowindex="2"
                          >
                            <td
                                aria-selected="false"
                                tabindex="-1"
                                colspan="1"
                                aria-colindex="1"
                            >
                              <i
                                  v-if="dataItem.editing"
                                  @click="removeOrderPosition(dataItem, key)"
                                  class="la la-times remove-order-item"
                              ></i>
                              {{
                                item.product_name
                              }}
                            </td>
                            <td
                                aria-selected="false"
                                tabindex="-1"
                                colspan="1"
                                aria-colindex="3"
                            >
                              {{
                                item.quantity
                              }}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item key="client" id="client">
                    <v-container class="ml-0 grey lighten-5">
                      <div>
                        <table class="client-table table table-striped m-table">
                          <tbody>
                          <tr>
                            <td>ФИО:</td>
                            <td>
                              <p>
                                {{
                                  dataItem
                                      .subdivision_user
                                      .name
                                }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td width="250px">
                              Структурное
                              подразделение:
                            </td>
                            <td>
                              <p>
                                {{
                                  dataItem.structural_subdivision_name
                                }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Тип
                              пользователя:
                            </td>
                            <td>
                              <p>
                                {{
                                  dataItem
                                      .subdivision_user
                                      .type ===
                                  1
                                      ? 'Руководитель'
                                      : 'Сотрудник'
                                }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>E-mail:</td>
                            <td>
                              <p>
                                {{
                                  dataItem
                                      .subdivision_user
                                      .email
                                }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>Телефон:</td>
                            <td>
                              <p>
                                {{
                                  dataItem
                                      .subdivision_user
                                      .phone_number
                                }}
                              </p>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item key="history" id="history">
                    <v-container
                        class="ml-0 grey lighten-5"
                    >
                      <div
                          v-if="
                              dataItem.status_history &&
                              dataItem.status_history.length
                          "
                      >
                        <table
                            class="history-table-header"
                            role="presentation"
                        >
                          <thead role="presentation">
                          <tr
                              role="row"
                              aria-rowindex="1"
                          >
                            <th
                                aria-readonly="true"
                                aria-selected="false"
                                role="columnheader"
                                tabindex="-1"
                                class="k-header"
                                rowspan="1"
                                colspan="1"
                                aria-colindex="1"
                            >
                              Дата заказа
                              <span
                                  class="k-column-resizer"
                                  style="
                                                                        display: block;
                                                                        right: 0px;
                                                                    "
                              ></span>
                            </th>
                            <th
                                aria-readonly="true"
                                aria-selected="false"
                                role="columnheader"
                                tabindex="-1"
                                class="k-header"
                                rowspan="1"
                                colspan="1"
                                aria-colindex="3"
                                style=""
                            >
                              Статус
                              <span
                                  class="k-column-resizer"
                                  style="
                                                                        display: block;
                                                                        right: 0px;
                                                                    "
                              ></span>
                            </th>
                          </tr>
                          </thead>
                        </table>
                        <table
                            class="history-table k-grid-table"
                            role="presentation"
                            style="
                                                        transform: translateY(
                                                            0px
                                                        );
                                                    "
                        >
                          <tbody role="presentation">
                          <tr
                              v-for="(item, key) in dataItem.status_history"
                              :key="key"
                              class="k-alt"
                              data-kendo-grid-item-index="0"
                              role="row"
                              aria-rowindex="2"
                          >
                            <td
                                aria-selected="false"
                                tabindex="-1"
                                colspan="1"
                                aria-colindex="1"
                            >
                              {{
                                  moment(item.datetime)
                                  .format('YYYY-MM-DD HH:mm:ss')
                              }}
                            </td>
                            <td
                                aria-selected="false"
                                tabindex="-1"
                                colspan="1"
                                aria-colindex="3"
                            >
                              {{ getProductOrderStatusNameByKey(item.status) }}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-container>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </div>
          </template>
          <template v-slot:date_filter>
            <div class="d-flex align-end">
              <datepicker
                  :default-show="defaultShow"
                  :value="value"
                  :start-date-input-settings="startDateInputSettings"
                  :end-date-input-settings="endDateInputSettings"
                  @change="dateChangeHandler"
              />
              <button
                  @click="clearDates"
                  title="Clear"
                  type="button"
                  class="k-button k-button-icon k-clear-button-visible"
              >
                <span class="k-icon k-i-filter-clear"></span>
              </button>
            </div>
          </template>
          <template v-slot:actions="{ props: { dataItem } }">
            <td>
              <div class="actionsColumn">
                <CButton
                    :disabled="!dataItem.can_change_status_by_agent"
                    @click="
                        currentItem = dataItem;
                        showEntityModal = true;
                    "
                    class="actions-btn btn-square btn-info"
                >
                  <i class="la la-pencil"></i>
                  Изменить статус
                </CButton>
              </div>
            </td>
          </template>
        </Grid>
      </intl>
    </localization>
    <change-status-modal
        order-type="subdivision"
        v-if="showEntityModal"
        :current-item="currentItem"
        :show-dialog="showEntityModal"
        @click:outside="showEntityModal = false"
        @stored="entityStored($event)"
        @close="showEntityModal = false"
    />
    <order-position-deleting-modal
        order-type="subdivision"
        v-if="showOrderPositionDeletingModal"
        :current-item="currentItem"
        :show-dialog="showOrderPositionDeletingModal"
        @click:outside="showOrderPositionDeletingModal = false"
        @stored="
            showOrderPositionDeletingModal = false;
            updateTable();
        "
        @close="showOrderPositionDeletingModal = false"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import TableMixin from '@/views/grid_elements/mixins/TableMixin';
import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin';
import {Grid} from '@progress/kendo-vue-grid';
import {
  IntlProvider,
  LocalizationProvider,
} from '@progress/kendo-vue-intl';
import BooleanFilter from '@/views/components/BooleanFilter';
import Breadcrumbs from '@/views/components/breadcrumbs';
import '@/views/grid_elements/translations';
import api from '@/api';
import OrderStatuses from '@/config/order_statuses';
import DeliveryTypes from '@/config/delivery_types';
import {DropDownList} from '@progress/kendo-vue-dropdowns';
import ChangeOrderStatusModal from '@/views/content/agent-user/modals/ChangeOrderStatusModal';
import ApproveOrderPositionDeletingModal from '@/views/content/agent-user/modals/ApproveOrderPositionDeletingModal';
import {error} from '@/lib/toasted';
import {showConfirm} from '@/lib/swal2';
import {toDataSourceRequestString} from '@progress/kendo-data-query';
import {DateRangePicker} from '@progress/kendo-vue-dateinputs';

export default {
  name: 'StructuralSubdivisionProductOrder',
  components: {
    Grid: Grid,
    breadcrumbs: Breadcrumbs,
    'boolean-filter': BooleanFilter,
    localization: LocalizationProvider,
    intl: IntlProvider,
    DropDownList,
    'change-status-modal': ChangeOrderStatusModal,
    'order-position-deleting-modal': ApproveOrderPositionDeletingModal,
    datepicker: DateRangePicker,
  },
  mixins: [TableMixin, ResizableTableMixin],
  async mounted() {
    await this.getData()
    this.fetchSubdivisionListShort()
  },
  data: function () {
    return {
      skip: 0,
      take: 10,
      pageSize: 10,
      total: 7,
      value: {
        start: null,
        end: null,
      },
      defaultShow: false,
      startDateInputSettings: {
        label: 'От',
        format: 'dd.MM.yyyy',
      },
      endDateInputSettings: {
        format: 'dd.MM.yyyy',
        label: 'До',
      },
      expandedItems: [],
      gridPageable: {pageSizes: true},
      detailTemplate: 'detail',
      sort: [{field: 'product_order_date', dir: 'desc'}],
      columns: [
        {
          field: 'id',
          title: 'Действия',
          cell: 'actions',
          minWidth: 150,
          width: '150px',
          filterable: false,
          sortable: false,
        },
        {
          field: 'product_order_number',
          title: 'Номер',
          minWidth: 200,
          width: '200px',
          filter: 'text',
          type: 'text',
        },
        {
          field: 'product_order_date',
          title: 'Дата',
          filter: 'date',
          type: 'date',
          format: '{0:d}',
          minWidth: 400,
          width: '400px',
          filterCell: 'date_filter',
        },
        {
          field: 'product_order_status',
          title: 'Статус',
          filterCell: 'status_filter',
          cell: 'status',
          minWidth: 250,
          width: '250px',
          filter: 'text',
          type: 'text',
        },
        {
          field: 'structural_subdivision_name',
          title: 'Структурное подразделение',
          minWidth: 250,
          width: '250px',
          filter: 'text',
          type: 'text',
        },
        {
          field: 'amount',
          title: 'Сумма',
          minWidth: 200,
          width: '200px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'structural_subdivision_delivery_is_order_urgent',
          title: 'Срочный заказ?',
          filterCell:
              'subdivision_delivery_is_order_urgent_filter',
          cell: 'subdivision_delivery_is_order_urgent',
          minWidth: 200,
          width: '200px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'structural_subdivision_delivery_type',
          title: 'Тип доставки',
          filterCell: 'subdivision_delivery_type_filter',
          cell: 'subdivision_delivery_type',
          minWidth: 250,
          width: '250px',
          filter: 'text',
          type: 'text',
        },
        {
          field: 'region_and_city',
          title: 'Регион/Город',
          minWidth: 200,
          width: '200px',
          filter: 'text',
          type: 'text',
        },
        {
          field: 'structural_subdivision_delivery_address',
          title: 'Адрес',
          minWidth: 200,
          width: '200px',
          filter: 'text',
          type: 'text',
        },
        {
          field: 'structural_subdivision_delivery_comment',
          title: 'Комментарий',
          minWidth: 200,
          width: '200px',
          filter: 'text',
          type: 'text',
        },
        {
          title: 'Контактная информация',
          minWidth: 600,
          width: '600px',
          children: [
            {
              field: 'structural_subdivision_delivery_recipient_name',
              title: 'ФИО получателя',
              width: '200px',
              type: 'text',
              filter: 'text',
            },
            {
              field: 'structural_subdivision_delivery_additional_customer_email',
              title: 'E-mail получателя',
              width: '250px',
              type: 'text',
              filter: 'text',
            },
            {
              field: 'structural_subdivision_delivery_contact_number',
              title: 'Контактный телефон получателя',
              width: '200px',
              type: 'text',
              filter: 'text',
            },
            {
              field: 'structural_subdivision_delivery_optional_contact_number',
              title: 'Дополнительный контактный телефон получателя',
              width: '200px',
              type: 'text',
              filter: 'text',
            },
          ],
        },
        {
          field: 'structural_subdivision_delivery_customer_email',
          title: 'Е-майл заказчика',
          minWidth: 200,
          width: '200px',
          filter: 'text',
          type: 'text',
        },
        {
          field: 'structural_subdivision_delivery_customer_phone_number',
          title: 'Телефон заказчика',
          minWidth: 200,
          width: '200px',
          filter: 'text',
          type: 'text',
        },
        {
          field: 'structural_subdivision_act',
          title: 'Акт выдачи',
          type: 'text',
          cell: 'structural_subdivision_act',
          minWidth: 150,
          width: '150px',
        },
        {
          field: 'structural_subdivision_mx',
          title: 'МХ-3',
          type: 'text',
          cell: 'structural_subdivision_mx',
          minWidth: 150,
          width: '150px',
        },
      ],
      tab: null,
      OrderStatuses,
      DeliveryTypes,
      showChangeStatusModal: false,
      showOrderPositionDeletingModal: false,
    }
  },
  methods: {
    ...mapActions('account', ['loginAsAgent']),
    ...mapActions('agent_user_orders', ['fetchSubdivisionListShort']),
    fetchData: api.orders.getAgentUserSubdivisionProductOrders,
    async prepareData() {
      try {
        const fetchParams = this.prepareFetchParams()
        const {
          data: {data},
        } = await this.fetchData(...fetchParams)

        data.items = data.items.map(item => {
          item.initialItems = [...item.items];
          return item;
        });

        return data;
      } catch (e) {
      }
    },
    dateChangeHandler({value}) {
      this.value.start = value.start
      this.value.end = value.end
      if (value.start || value.end) {
        const dateStart = new Date(value.start).toLocaleDateString(
            'ru-RU'
        );
        const dateEnd = new Date(value.end).toLocaleDateString(
            'ru-RU'
        );
        if (dateEnd === '01.01.1970') {
          this.setFilter('date', 'gte', dateStart);
        } else if (dateStart === dateEnd) {
          this.removeFilter('date');
          this.setFilter('date', 'gtedatetime', dateStart);
        } else {
          this.removeFilter('date');
          const obj = [
            {
              field: 'date',
              operator: 'gte',
              value: dateStart + 'T00:00:00',
            },
            {
              field: 'date',
              operator: 'lte',
              value: dateEnd + 'T23:59:59',
            },
          ];
          this.setMultipleFilter('date', obj);
        }
      } else {
        this.removeFilter('dealer_id');
      }
      this.updateTable(true);
    },
    clearDates() {
      this.value.start = null
      this.value.end = null
      this.clearFilters()
      this.updateTable(true);
    },
    selectStatus({value}) {
      if (value.key) {
        this.setFilter('product_order_status', 'eq', value.key);
      } else {
        this.removeFilter('product_order_status');
      }

      this.updateTable(true);
    },
    prepareFetchParams() {
      return [
        this.page,
        this.take,
        toDataSourceRequestString({
          filter: this.filter,
          sort: this.sort,
        }),
      ];
    },
    selectSubdivision({value}) {
      if (value.id) {
        this.setFilter('structural_subdivision_id', 'eq', value.id);
      } else {
        this.removeFilter('structural_subdivision_id');
      }

      this.updateTable(true);
    },
    selectDeliveryType({value}) {
      if (value.key) {
        this.setFilter(
            'structural_subdivision_delivery_type',
            'eq',
            value.key
        );
      } else {
        this.removeFilter('structural_subdivision_delivery_type');
      }

      this.updateTable(true);
    },
    editOrderPositions(item) {
      if (item.items.length == 1) {
        error(
            'Данный заказ нельзя редактировать. Для удаления позиции заказ можно отменить.'
        );
        return;
      }

      this.$set(item, 'editing', true);
    },
    async removeOrderPosition(item, index) {
      if (item.items.length == 1) {
        error('Нельзя удалить последнюю позицию заказа.');
        return;
      }

      const {isConfirmed} = await showConfirm(
          'Вы уверены, что хотите удалить товар из заказа?'
      );

      if (isConfirmed) {
        let items = item.items;
        let deletedItems = item.deletedItems || [];

        deletedItems.push(items[index].id);
        items.splice(index, 1);

        Object.assign(item, {
          items: [...items],
          deletedItems: [...deletedItems],
        });
      }
    },
    cancelOrderPositionEditing(order) {
      Object.assign(order, {
        editing: false,
        items: [...order.initialItems],
        deletedItems: [],
      });
    },
    async exportRequsts() {
      let res = []
      res = this.filter.filters.filter((el) => el.field === 'date')
      const currentDate = new Date().toLocaleDateString('ru-RU');
      if (res.length === 1) {
        res.push({
          field: 'date',
          operator: 'lte',
          value: currentDate
        })
      }
      this.filter = {
        filters: res,
        logic: 'and'
      }
      try {
        const response =await api.orders.getExportRequests(
          toDataSourceRequestString({
              filter: this.filter,
              sort: this.sort,
          }))

        const fileURL = window.URL.createObjectURL(
            new Blob([response.data])
        )
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute(
            'download',
            `Экспорт заявки в службу доставки.xlsx`
        )
        document.body.appendChild(fileLink)

        fileLink.click()
      } catch (e) {
        console.warn(e)
      }
    },
  },
  computed: {
    ...mapGetters('orders', [
      'getProductOrderStatusNameByKey',
      'getDeliveryTypeNameByKey',
    ]),
    ...mapGetters('agent_user_orders', ['subdivisionsListShort']),
  },
};
</script>

<style lang="sass" scoped>
.details-table::v-deep
  tr
    td
      &:nth-child(1)
        width: 200px

.order-items-table-header::v-deep
  tr
    th
      &:nth-child(1)
        width: 500px

.order-items-table::v-deep
  tr
    td
      &:nth-child(1)
        width: 500px

.client-table::v-deep
  tr
    td
      &:nth-child(1)
        width: 300px


.history-table-header::v-deep
  tr
    th
      &:nth-child(1)
        width: 300px

.history-table::v-deep
  tr
    td
      &:nth-child(1)
        width: 300px

.actions-btn
  white-space: normal
  padding: 3px 10px !important

  &.btn::after
    content: none !important

.order-item-actions
  margin-top: 3px
  margin-bottom: 13px

  .edit-order-btn
    font-size: 12px
    padding: 0.3rem 0.8rem

.order-items-table
  .remove-order-item
    cursor: pointer
    color: #060a13
    font-size: 16px
    font-weight: bold
    margin-right: 8px

.export-desc
  text-align: right
</style>
