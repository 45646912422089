import { render, staticRenderFns } from "./StructuralSubdivisionProductOrders.vue?vue&type=template&id=097c1b06&scoped=true&"
import script from "./StructuralSubdivisionProductOrders.vue?vue&type=script&lang=js&"
export * from "./StructuralSubdivisionProductOrders.vue?vue&type=script&lang=js&"
import style0 from "./StructuralSubdivisionProductOrders.vue?vue&type=style&index=0&id=097c1b06&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "097c1b06",
  null
  
)

export default component.exports